import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import CountryService from '@/services/country.service'


export default {
  props: [],
  name: 'ImportPersonalizateField',
  components: { ButtonMultimedia },
  data () {
    return {
      voiceTemplate: null,
      voicePayload: null,
      htmlLanding: null,
      dataCountry: null,
      dataCountryKeys: null,
      countrySelected: null,
      itemSelected: {
        name: 'Sample100.csv',
        path: 'csv',
        type: 'file',
        extension: 'csv',
        size: '7.04 KB',
        date: '2021-06-30 10:22:12',
        url: 'http://localhost:8081/multimedia-managers/file?path=csv/Sample100.csv&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODA4MVwvYXBpXC9sb2dpbiIsImlhdCI6MTYyOTQ3Mjc0MCwiZXhwIjoxNjI5NDc2MzQwLCJuYmYiOjE2Mjk0NzI3NDAsImp0aSI6IjJPaTZ3OWZGaU9tQnhOVk8iLCJzdWIiOjEsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.vEqnqLVeNdtRUlEnh4FvmDQa8KAsietpjJVoalPh7Qw',
        fileType: 'csv',
        csv: {
          header: [
            'Serial Number',
            'Company Name',
            'Employee Markme',
            'Description',
            'Leave',
          ],
          body: [
            [
              '9788189999599',
              '9780099578079',
              '9780198082897',
              '9780007880331',
              '9780545060455',
            ],
            [
              'TALES OF SHIVA',
              '1Q84 THE COMPLETE TRILOGY',
              'MY KUMAN',
              'THE GOD OF SMAAL THINGS',
              'THE BLACK CIRCLE',
            ],
            [
              'Mark',
              'HARUKI MURAKAMI',
              'Mark',
              'ARUNDHATI ROY',
              'Mark',
            ],
            [
              'mark',
              'Mark',
              'Mark',
              '4TH HARPER COLLINS',
              '4TH HARPER COLLINS',
            ],
            [
              '0',
              '0',
              '0',
              '2',
              '0',
            ],
          ],
        },
        fileId: null,
        personalizateItem: {
          item: 'item',
          typeof: 'typeof',
        },
      },
      columName: {
        email: null,
        phone: null,
        landline: null,
        createdAt: null,
        name: null,
        surname: null,
      },
      countryIso: null,
    }
  },
  methods: {
    validateItem (itemSelected) {
      if (itemSelected.type === 'file') {
        return true
      }
    },
    selectCountry () {
      this.countryIso = this.dataCountryKeys[this.dataCountry.indexOf(this.countrySelected)]
    },
    getCoutries () {
      CountryService.getCountries()
        .then(
          (response) => {
            this.dataCountry = Object.values(JSON.parse(JSON.stringify(response)))
            this.dataCountryKeys = Object.keys(JSON.parse(JSON.stringify(response)))
          },
          () => { },
        )
        .finally(() => {
        })
    },
  },
  created () {
  },
  mounted () {
    this.getCoutries()
  },
}
