import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import ContactsService from '@/services/contacts.service'
import DateRange from '@/components/Graphs/DateRange/DateRange.vue'
import Syncdb from './Syncdb/Syncdb.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'Sync',
  components: { HeaderTopDashboard, StatsTitle, DateRange, Syncdb },
  data: function () {
    return {
      tab: [],
      content: {
        id: 'hi',
        email: 'hi',
        phone: 'hi',
        landline: 'hi',
        country_iso: 'hi',
      },
      show: false,
      globalControl: new GlobalControl(),
      loading: true,
      searchTerm: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      selected: [],
      totalItems: 0,
      headers: [
        { text: this.$t('Id'), align: 'start', value: 'id' },
        { text: 'Email', align: 'start', value: 'email' },
        { text: this.$t('Teléfono Móvil'), align: 'start', value: 'phone' },
        { text: this.$t('Teléfono Fijo'), align: 'start', value: 'landline' },
        { text: this.$t('País'), align: 'start', value: 'country_iso' },
        { text: this.$t('Fecha alta'), align: 'start', value: 'createdAt' },
        { text: this.$t('Lista negra'), align: 'start', value: 'hasBlacklist' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedItem: {
        email: '',
      },
      defaultItem: {
        email: '',
      },
      data: [],
      ready: true,
    }
  },
  methods: {
    initialize (data) {
      this.content = data.map((x) => {
        return {
          /*
          id: x.id,
          email: x.email,
          phone: x.phone,
          landline: x.landline,
          country_iso: x.country_iso,
          createdAt: x.createdAt,
          */
        }
      })
    },
    getDataFromApi () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }

      this.content = []
      this.loading = true
      ContactsService.getChannelDatatable(params)
        .then(
          (response) => {
            this.data = JSON.parse(JSON.stringify(response.contacts.data))
            this.initialize(response.contacts.data)
            this.itemsPerPage = parseInt(response.contacts.per_page)
            this.page = parseInt(response.contacts.current_page)
            this.totalItems = parseInt(response.contacts.total)
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
        })
    },
    editItem (item) {
      this.editedIndex = this.content.indexOf(item)
      this.editedItem = Object.assign({}, this.data[this.editedIndex])
      this.dialog = true
      this.countrySelected = this.dataCountry[this.dataCountryKeys.indexOf(this.editedItem.country_iso)]
    },

    deleteItem (item) {
      this.editedIndex = this.content.indexOf(item)
      this.editedItem = Object.assign({}, this.data[this.editedIndex])
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.content.splice(this.editedIndex, 1)
      this.loading = true
      ContactsService.deleteDataForm(this.editedItem)
        .then(
          (response) => {
            this.getDataFromApi()
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
        })
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.countrySelected = ''
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (!this.editedIndex > -1) {
        this.loading = true
        ContactsService.postDataForm(this.editedItem)
          .then(
            (response) => {
              this.getDataFromApi()
            },
            () => {},
          )
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = true
        ContactsService.updateDataForm(this.editedItem)
          .then(
            (response) => {
              this.getDataFromApi()
            },
            () => {},
          )
          .finally(() => {
            this.loading = false
          })
      }
      this.close()
    },
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? this.$t('Nuevo contacto') : this.$t('Editar contacto')
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
  },
  watch: {
    globalControl: {
      handler: function () {
        this.getDataFromApi()
      },
      deep: true,
    },
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  created () {
  },
  mounted () {
  },
}
