
import ImportFtp from './ImportFtp/ImportFtp.vue'
import ImportPersonalizateField from './ImportPersonalizateField/ImportPersonalizateField.vue'
import SetGroups from './SetGroups/SetGroups.vue'
import SyncService from '@/services/sync.service'

export default {
  name: 'Syncdb',
  components: { SetGroups, ImportPersonalizateField, ImportFtp },
  props: [],
  data: function () {
    return {
      preview: [],
      invalid: true,
      tab: [],
      item: [
        { id: 30, name: this.$t('30 minutos') },
        { id: 60, name: this.$t('60 minutos') },
        { id: 90, name: this.$t('90 minutos') },
        { id: 120, name: this.$t('120 minutos') },
        { id: 180, name: this.$t('3 horas') },
        { id: 360, name: this.$t('6 horas') },
        { id: 720, name: this.$t('12 horas') },
        { id: 1440, name: this.$t('24 horas') },
        { id: 2880, name: this.$t('2 días') },
        { id: 4320, name: this.$t('3 días') },
        { id: 10080, name: this.$t('7 días') },
        { id: 21600, name: this.$t('15 días') },
        { id: 43200, name: this.$t('30 días') },
      ],
      editedItem: {
        type: 'synchronized',
        name: '',
        url: '',
        frequency: '',
        separator: '',
        skipHeader: '',
      },
      separator: [',', ';'],
      header: [
        { name: 'Si', id: true },
        { name: 'No', id: false },
      ],
    }
  },
  methods: {
    step (number) {
      switch (number) {
        case 1:
          this.uploadFile()
          break
        default:
          break
      }
    },
    uploadFile () {
      this.loading = true
      SyncService.uploadFile(this.editedItem)
        .then(
          (response) => {
            if (response.success === true) {
              this.preview = response.preview
              this.tab++
            }
            this.loading = false
          },
          () => {},
        )
        .finally(() => {
          this.loading = false
        })
    },
  },
}
