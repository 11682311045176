import { Service } from './service'

const PATH = 'import/'

class SyncService extends Service {
  uploadFile (params = {}) {
    return this.post(PATH + 'upload-file', params)
  }

  uploadFileFilter (params = {}) {
    return this.post(PATH + 'upload-file-filter', params)
  }

  cleanUp (params = {}) {
    return this.post(PATH + 'filter-csv-clean-up', params)
  }
}

export default new SyncService()
